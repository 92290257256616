import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import './Spinner.sass';

const Spinner = (props) => {

    return ( 
        <div className="spinner-wrapper" style={{backgroundColor: props.backgroundColor}}>
            <CircularProgress className="spinner"/>
        </div>
     );
}

Spinner.propTypes = {
    backgroundColor: PropTypes.string
 };
 
export default Spinner;