import React, {createContext, useContext, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useMutation} from 'react-query';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {queryCache} from 'react-query';
import config from '../config/config';
import {Dialog, DialogTitle} from '@material-ui/core';
import Spinner from '../components/spinner/Spinner';
import Button from '../components/button/Button';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
let timeoutId = null;
const AuthContext = createContext();

const AuthProvider = (props) => {

    const [user, setUser] = useState({role: "", firstName: "", lastName: "", email: "", balance: 0, status: undefined});

    const history = useHistory();
    const {t, i18n} = useTranslation();
    const [customError, setCustomError] = useState(false);
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const options = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${window.btoa(`${config.clientId}:${config.secretId}`)}`
        }
    };

    const isAdminMode = window.location.href.includes('admin');

    const [userInfo, {isLoading: userInfoLoading}] = useMutation((isRefetching) => axios.get('/api/userinfo',  {headers : {'Authorization': `Bearer ${getAccessToken()}`}}), {

            onSuccess: (response, variables) => {

                const data = response.data;
                setUser(data);

                if(isAdminMode && !data.role.includes('APP_OPERATOR')) {
                    setCustomError({value: "Invalid permissions", error: "Invalid permissions"});
                    logOut();
                    return;
                }

                if(variables && variables.isRefetching) {
                    return;
                }

                if(isAdminMode && data.role.includes('APP_OPERATOR')) {
                    i18n.changeLanguage('pl');
                    (!variables || !variables.isRefetching) && history.push('/admin/meal-plan/schools');
                } else if(!isAdminMode && data.role.includes('APP_USER')) {
                    (!variables || !variables.isRefetching) && history.push(localStorage.getItem('REDIRECT_URL') || '/dashboard');
                } else {
                    setCustomError({value: "Invalid permissions", error: "Invalid permissions"});
                    return;
                }
             }
    });

    const [logIn, {error, isLoginLoading}] = useMutation(({username, password}) => axios.post('/oauth/token', `grant_type=password&username=${username}&password=${password}&scope=ui`, {...options, skipAuthRefresh: true }), {
        onMutate: () => {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);
            localStorage.removeItem('logout-event');
        },
        onSuccess: response => {
            const data = response.data;
            saveTokens(data);
            userInfo();
        }
    });

    const refreshToken = failedRequest => axios.post('/oauth/token', `refresh_token=${localStorage.getItem(REFRESH_TOKEN)}&grant_type=refresh_token`, {...options, skipAuthRefresh: true })
        .then( response => {
            saveTokens(response.data);
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + response.data[ACCESS_TOKEN];
            return Promise.resolve();
        })
        .catch( error => {
            if(!timeoutId && !history.location.pathname.includes('auth/login')) {
                timeoutId = setTimeout(logOut, 10000);
                setIsSessionExpired(true);
            }
        });

    useEffect(() => {

        sessionStorage.clear();

        const handleIdleTimerEvents = event => {

            if (event.key === 'logout-event' && localStorage.getItem('logout-event')) {
                if(isAdminMode){
                    history.push('/admin/auth/login');
                } else {
                    history.push('/auth/login');
                }
            }
        }

        window.addEventListener('storage', handleIdleTimerEvents);

        const refToken = localStorage.getItem(REFRESH_TOKEN);
        if(refToken) { 
            setIsLoading(true);
            axios.post('/oauth/token', `refresh_token=${refToken}&grant_type=refresh_token`, {...options, skipAuthRefresh: true })
            .then( response => {
                setIsLoading(false);
                saveTokens(response.data);
            })
            .catch( error => {

                setIsLoading(false);
                logOut();
            });
            userInfo({isRefetching: true});
        }
        createAuthRefreshInterceptor(axios, refreshToken);

        return () => window.removeEventListener('storage', handleIdleTimerEvents);
    }, []);

    const logOut = () => {

        const options  = {
            headers : {
                'Authorization': `Bearer ${getAccessToken()}`
            }
        };

        axios.delete('/oauth/revoke', {...options, skipAuthRefresh: true });
        clearTimeout(timeoutId);
        timeoutId = null;
        setIsSessionExpired(false);
        setCustomError(null);
        queryCache.clear();
        localStorage.setItem('logout-event', true);
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        if(isAdminMode){
            history.push('/admin/auth/login');
        } else {
            history.push('/auth/login');
        }
    }

    const isAuthenticated = () => {

        return localStorage.getItem(ACCESS_TOKEN) !== null;
    };

    const getAccessToken = () => {
        return localStorage.getItem(ACCESS_TOKEN) ;
    };

    const getUserFirstName = () => {
        return user.firstName;
    };

    const getUserLastName = () => {
        return user.lastName;
    };

    const getUserRole = () => {
        return user.role;
    };

    const getUserBalance = () => {
        return user.balance;
    }

    const getUserEmail = () => {
        return user.email;
    }

    const getUserStatus = () => {
        return user.status;
    }

    const saveTokens = (data) => {
        localStorage.setItem(ACCESS_TOKEN, data[ACCESS_TOKEN]);
        localStorage.setItem(REFRESH_TOKEN, data[REFRESH_TOKEN]);
    }

    return (
        <AuthContext.Provider value={{isAuthenticated, logIn, logOut, isAdminMode, getUserRole, getUserFirstName, getUserLastName, getUserBalance, getUserEmail, getUserStatus, getAccessToken, userInfo, error: error || customError, isLoading: isLoginLoading || userInfoLoading}}>
            {isLoading && <Spinner/>}
            {props.children}
            <Dialog classes={{paper: 'session-dialog'}} onClose={logOut} open={!!isSessionExpired && !history.location.pathname.includes('auth/login')}>
                <DialogTitle className="session-title">{t('auth.sessionExpired.title')}</DialogTitle>
                <p className="session-msg">{t('auth.sessionExpired.message')}</p>
                <Button className="session-btn" onClick={logOut}>OK</Button>
            </Dialog>
        </AuthContext.Provider>
    );
}

export {AuthProvider};
export const useAuth = () => useContext(AuthContext);
