import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import MefkApp from './MefkApp';
import Spinner from './components/spinner/Spinner';
import './i18n';
import './index.sass';

ReactDOM.render(document.getElementById('unsupportedBrowser').style.display === "none" &&
  <React.StrictMode>
    <Suspense fallback={<Spinner/>}>
      <MefkApp />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
