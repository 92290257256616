import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuth} from '../../hooks/useAuth';

const PrivateRoute = ({component: Component, link, ...rest}) => {

    const {isAuthenticated} = useAuth();

    return (
        <Route {...rest} render={(props) => (
            isAuthenticated() ?
            <Component {...props} /> :
            <Redirect to={`${link ? link : ''}/auth/login`} />
        )}/>
    );
}

export default PrivateRoute;