import i18n from 'i18next';
import backend from 'i18next-xhr-backend';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next)
    .init({
        fallbackLng: ["pl", "en"],
        debug: false,
        load: 'languageOnly',
        interpolation: {
            escapeValue: false
        }
    });


export default i18n;