import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ReactQueryConfigProvider} from 'react-query';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {AuthProvider} from './hooks/useAuth';
import PrivateRoute from './components/privateroute/PrivateRoute';

const AuthorisationPanel = React.lazy(() => import('./pages/auth/AuthorisationPanel'));
const UserPanel = React.lazy(() => import('./pages/user/UserPanel'));
const AdminPanel = React.lazy(() => import('./pages/admin/AdminPanel'));
const PaymentStatus = React.lazy(() => import('./pages/user/paymnetstatus/PaymentStatus'));

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Lato',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(','),
        body2: {
            fontWeight: 600
        }
    },
    palette: {
      primary: {
          main: '#515953'
      },
      text: {
          primary: '#515953'
      }
  },
});

const queryConfig = {
    queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        retry: 1,
        cacheTime: 1000 * 60 * 3
    }
}

function MefkApp() {
    return (
        <ThemeProvider theme={theme}>
            <ReactQueryConfigProvider config={queryConfig}>
                <Router>
                    <AuthProvider>
                        <Switch>
                            <Route path='/auth' component={AuthorisationPanel}/>
                            <Route path='/admin/auth' component={AuthorisationPanel}/>
                            <Route path='/payment/success' component={() => <PaymentStatus severity='success'/>}/>
                            <Route path='/payment/error' component={() => <PaymentStatus severity='error'/>}/>
                            <PrivateRoute path='/admin' link={'/admin'} component={AdminPanel}/>
                            <PrivateRoute path='/' component={UserPanel}/>
                        </Switch>
                    </AuthProvider>
                </Router>
            </ReactQueryConfigProvider> 
        </ThemeProvider>
    );
}

export default MefkApp;
