import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Button as Btn} from '@material-ui/core';
import './Button.sass';

const Button = (props) => {

    return (
        <Btn 
        className={classNames('btn', props.className, {'btn--small': props.size === 'small'})} 
        color={props.color === 'secondary' ? 'secondary' : 'primary'}
        variant={props.variant || 'contained'}
        onClick={props.onClick}
        startIcon={props.icon}
        disabled={props.disabled}
        type={props.type}
        classes={{
            containedPrimary: props.color === 'error' ? 'btn--contained-error' : 'btn--contained-primary',
            containedSecondary: 'btn--contained-secondary',
            textPrimary: 'btn--text-primary',
            textSecondary: 'btn--text-secondary',
            outlinedPrimary: props.color === 'error' ? 'btn--outlined-error' : 'btn--outlined-primary',
            outlinedSecondary: 'btn--outlined-secondary',
            startIcon: 'btn__icon',
            disabled: 'btn--disabled'
        }}
        >
            {props.children}
        </Btn>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'error']),
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
    icon: PropTypes.element,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium']),
    children: PropTypes.any,
}

export default Button;